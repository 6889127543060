jQuery(function($) {


	// ----
	// Menú
	// ----

	$('#menu').hide();

	$('#menu-toggle').on('click', function() {

		var isHidden = $("#menu").is(":hidden");

		if (isHidden) {
			$('#menu').slideDown();
		}
		else {
			$('#menu').slideUp();
		}

	});

	var $hamburger = $(".hamburger");

	$hamburger.on("click", function(e) {
		$hamburger.toggleClass("is-active");
	});


	// ------------
	// Sicky Header
	// ------------

	const body = document.body;
	const scrollUp = "scroll-up";
	const scrollDown = "scroll-down";
	let lastScroll = 0;

	const navBar = document.getElementById('navbar-collapse');

	window.addEventListener("scroll", () => {

		const currentScroll = window.pageYOffset;

		if (currentScroll == 0) {
			body.classList.remove(scrollUp);
			return;
		}

		if (currentScroll > lastScroll && !body.classList.contains(scrollDown) 
			&& !navBar.classList.contains('show')) {
			// down
			body.classList.remove(scrollUp);
			body.classList.add(scrollDown);
		}
		else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
			// up
			body.classList.remove(scrollDown);
			body.classList.add(scrollUp);
		}

		lastScroll = currentScroll;

	});


	// --------
	// Leyendas
	// --------

	let uScroll = 0;

	$(window).on('scroll', function() {

		const cScroll = $(window).scrollTop();

		if (cScroll == 0) {
			$('.leyenda-progresemos').removeClass('fijo');
		}
		else if ( cScroll > uScroll && !$('.leyenda-progresemos').hasClass('fijo') ) {
			$('.leyenda-progresemos').addClass('fijo');
		}

		uScroll = cScroll;

	});


	// -----------
	// Slick Slide
	// -----------

	$('.slide-noticias').slick({
		prevArrow: '<div class="slick-arrow-left"><span class="fi fi-rr-angle-small-left"></span></div>',
		nextArrow: '<div class="slick-arrow-right"><span class="fi fi-rr-angle-small-right"></span></div>',
		dots: true,
		slidesToShow: 2,
		responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				centerMode: true,
				centerPadding: '128px'
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				// centerMode: true,
				// centerPadding: '48px'
			}
		},
		]
	});


	// -------
	// Masonry
	// -------

	// Fotos
	// -----

	var $grid = $('.grid-fotos').masonry({
		itemSelector: '.grid-item',
	});

	$grid.imagesLoaded().progress( function() {
		$grid.masonry('layout');
	});

	// Videos
	// ------

	function waitForvidLoad(vids, callback) {
		/* if no videos i.e. mobile mode only gifs and jpgs then call callback else masonry breaks.*/
		if (vids.length === 0){
			callback();
		}
		var vidsLoaded = 0;
		vids.on('loadeddata', function() {
			vidsLoaded++;
			if (vids.length === vidsLoaded) {
				callback();
			}
		});
	}

	var $container = $('.grid-videos');
	var vids = $('.grid-videos').find('video');
	waitForvidLoad(vids, function() {
		$container.imagesLoaded(function() {
			$container.masonry({
				itemSelector: '.grid-item',
			});
		});
	});


	// ----------------
	// Galería Fancybox
	// ----------------

	Fancybox.bind("[data-fancybox]", {
	});


	// ----------------------
	// Formulario de Contacto
	// ----------------------

	$('#contacto').validate();

	$('#contacto').on('submit', function(e) {

		// if the validator does not prevent form submit
		if (!e.isDefaultPrevented()) {

			var url = WPURLS.themedir + "/contacto.php";

			// POST values in the background the the script URL
			$.ajax({

				type: "POST",
				url: url,
				data: $(this).serialize(),
				success: function(data) {

					// data = JSON object that contact.php returns
					// we recieve the type of the message: success x danger and apply it to the 
					var messageAlert = data.type;
					var messageText = data.message;

					/*if (data == 'success') {
						var messageText = 'El mensaje ha sido enviado. ¡Gracias! En breve nos pondremos en contacto.';
					}
					else {
						var messageText = 'Hubo un error al enviar el mensaje. Por favor, inténtalo más tarde.';
					}*/

					// let's compose Bootstrap alert box HTML
					var alertBox = '<div class="alert alert-' + messageAlert + ' role-alert">' + messageText + '</div>';
					// var alertBox = '<div class="alert alert-' + data + ' role-alert">' + messageText + '</div>';

					// If we have messageAlert and messageText
					if (messageAlert && messageText) {
					// if (messageText) {
						// inject the alert to .messages div in our form
						$('#notificaciones').html(alertBox);
						// empty the form
						$('#contacto')[0].reset();
					}

				}

			});
			return false;

		}

	});


	// -----------------
	// Boletín Municipal
	// -----------------

	$('.boletines-lista button.btn').on('click', function() {
		$('.boletines-lista button.btn').removeClass('activo');
		$('.card-header').removeClass('activado');
		$(this).addClass('activo').closest('.card-header').addClass('activado');
		/*if ( !($(this).hasClass('activo')) ) {
			$('.boletines-lista button.btn').removeClass('activo');
			$('.card-header').removeClass('activado');
			$(this).addClass('activo').closest('.card-header').addClass('activado');
		}
		else {
			$(this).removeClass('activo').closest('.card-header').removeClass('activado');
		}*/
	});

	$('a.boletin-vinculo').on('click', function() {
		$('a.boletin-vinculo').removeClass('vinculo-activo');
		$(this).addClass('vinculo-activo');
	})


	// --------------------
	// Enlaces en el footer
	// --------------------

	$('.pie-enlaces').find('br').replaceWith(' ');


	// --------
	// Parallax
	// --------

	var images = document.getElementsByClassName('simple-parallax');
	new simpleParallax(images, {
		// scale: 1.0
	});


	// ---------------------
	// Alto mínimo de página
	// ---------------------

	function altoContenedor() {
		var altoDintel = $('.dintel').innerHeight();
		var altoPie = $('footer').innerHeight();
		var altoContenedor = $(window).innerHeight() + altoDintel - altoPie;

		$('.contenedor').css('min-height', altoContenedor);
	}
	altoContenedor();

	$(window).on('resize', function() {
		altoContenedor();
	});


});